const downloadImage = async (topicId: string, topicName: string, fileType: string = 'svg'): Promise<void> => {
	const url = `${process.env.REACT_APP_LIIINGO_URL}/exhibit/qr?id=${topicId}&fileType=${fileType}`;

	try {
		const response = await fetch(url);
		const blob = await response.blob();
		const download_link = document.createElement('a');
		download_link.href = URL.createObjectURL(blob);
		download_link.download = `${topicName}.${fileType}`;
		document.body.appendChild(download_link);
		download_link.click();
		document.body.removeChild(download_link);
	} catch (error) {
		console.error('Error downloading QR code:', error);
	}
};

export { downloadImage };
