import { IconButton, ListItem, ListItemSecondaryAction, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ContentCopy from '@material-ui/icons/FileCopyOutlined';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { _selectedTopic } from '../../store/slices/topicSlice';
import { colors } from '../../theme/palette';
import { LiiingoSuccessSnackbar } from '../LiiingoSuccessSnackbar';
import { LiiingoTooltip } from '../LiiingoTooltip';
import { drawerCollapsedWidth } from './LiiingoDrawer';
import { downloadImage } from '../../util/downloadQrcode';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			backgroundColor: colors.grayLight20,
		},
		titleFont: {
			fontSize: 14,
			fontWeight: 'bold',
		},
		list: {
			paddingLeft: drawerCollapsedWidth,
			paddingTop: 0,
		},
		download: {
			'&:hover': {
				backgroundColor: colors.purpleAccent20,
				color: colors.purpleAccent,
			},
			'&:active': {
				color: colors.pureWhite,
				backgroundColor: colors.purpleAccent,
			},
		},
		copy: {
			'&:hover': {
				backgroundColor: colors.tealAccent20,
				color: colors.tealAccent,
			},
			'&:active': {
				color: colors.pureWhite,
				backgroundColor: colors.tealAccent,
			},
		},
		actions: {
			display: 'flex',
		},
		qr: {
			width: '100%',
			padding: 15,
		},
	})
);

export const QrMenu: React.FC = () => {
	const topic = useAppSelector(_selectedTopic);
	const [copySuccess, setCopySuccess] = useState(false);
	const classes = useStyles();

	const copy = () => {
		navigator.clipboard.writeText(process.env.REACT_APP_LIIINGO_WEBAPP_URL + '/topic/' + topic.id);
		setCopySuccess(true);
	};

	return (
		<>
			<List className={classes.list}>
				<ListItem className={classes.title}>
					<Typography className={classes.titleFont}>QR Code</Typography>
					<ListItemSecondaryAction>
						<Box className={classes.actions}>
							<LiiingoTooltip message="Download" placement="bottom">
								<>
									<IconButton
										disableRipple
										size="small"
										className={classes.download}
										onClick={() => downloadImage(topic.id, topic.name[0].name, 'png')}
									>
										<img
											src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNDAgMjgwIj48cGF0aCBkPSJNNDAgMjAgSDE2MCBMMjAwIDYwIFYyNDAgUTIwMCAyNjAsIDE4MCAyNjAgIEg2MCAgUTQwIDI2MCwgNDAgMjQwIFY0MCBRNDAgMjAsIDYwIDIwIiBmaWxsPSJibGFjayIvPjxwYXRoIGQ9Ik0xNjAgMjAgTDE2MCA2MCBMMjAwIDYwIEwxNjAgMjAiIGZpbGw9IndoaXRlIi8+PHRleHQgeD0iMTIwIiB5PSIxNjAiIGZvbnQtZmFtaWx5PSJBcmlhbCwgc2Fucy1zZXJpZiIgZm9udC1zaXplPSI2NCIgZm9udC13ZWlnaHQ9ImJvbGQiIGZpbGw9IndoaXRlIiB0ZXh0LWFuY2hvcj0ibWlkZGxlIj5QTkc8L3RleHQ+PC9zdmc+"
											alt="PNG download icon"
											width="24"
											height="24"
										/>
									</IconButton>

									<IconButton
										disableRipple
										size="small"
										className={classes.download}
										onClick={() => downloadImage(topic.id, topic.name[0].name, 'svg')}
									>
										<img
											src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNDAgMjQwIj48cmVjdCB4PSI0MCIgeT0iMjAiIHdpZHRoPSIxNjAiIGhlaWdodD0iMjAwIiByeD0iMTAiIGZpbGw9ImJsYWNrIi8+PHBhdGggZD0iTTE2MCAyMCBMMTYwIDYwIEwyMDAgNjAgTDE2MCAyMCIgZmlsbD0id2hpdGUiLz48cmVjdCB4PSI2MCIgeT0iNDAiIHdpZHRoPSI4MCIgaGVpZ2h0PSIxMCIgZmlsbD0id2hpdGUiLz48cmVjdCB4PSI2MCIgeT0iNjAiIHdpZHRoPSI2MCIgaGVpZ2h0PSIxMCIgZmlsbD0id2hpdGUiLz48cmVjdCB4PSI2MCIgeT0iODAiIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAiIGZpbGw9IndoaXRlIi8+PHRleHQgeD0iMTIwIiB5PSIxNjAiIGZvbnQtZmFtaWx5PSJBcmlhbCwgc2Fucy1zZXJpZiIgZm9udC1zaXplPSI0OCIgZm9udC13ZWlnaHQ9ImJvbGQiIGZpbGw9IndoaXRlIiB0ZXh0LWFuY2hvcj0ibWlkZGxlIj5TVkc8L3RleHQ+PC9zdmc+"
											alt="SVG download icon"
											width="24"
											height="24"
										/>
									</IconButton>
								</>
							</LiiingoTooltip>
							<LiiingoTooltip message="Copy Link" placement="bottom">
								<IconButton disableRipple size="small" className={classes.copy} onClick={copy}>
									<ContentCopy fontSize="small" />
								</IconButton>
							</LiiingoTooltip>
						</Box>
					</ListItemSecondaryAction>
				</ListItem>
				<LiiingoTooltip
					placement="right"
					message="Scan this code with a mobile device to view the page you're currently designing."
				>
					<img
						src={process.env.REACT_APP_LIIINGO_URL + '/exhibit/qr?id=' + topic?.id}
						alt="QR Code"
						className={classes.qr}
					/>
				</LiiingoTooltip>
			</List>
			<LiiingoSuccessSnackbar
				open={copySuccess}
				text="Link copied successfully"
				onClose={() => setCopySuccess(false)}
			/>
		</>
	);
};
