import React, { useState } from 'react';
import { LiiingoSuccessSnackbar } from '../../../LiiingoSuccessSnackbar';
import { LiiingoContextMenu } from './LiiingoContextMenu';
import { downloadImage } from '../../../../util/downloadQrcode';
import { _topics } from '../../../../store/slices/topicSlice';
import { useAppSelector } from '../../../../store/hooks';

export type QRContextMenuProps = {
	topicId: string;
};

export const QRContextMenu: React.FC<QRContextMenuProps> = (props) => {
	const { children, topicId } = { ...props };
	const [copySuccess, setCopySuccess] = useState(false);
	const topics = useAppSelector(_topics);
	const selectedTopic = topics[topicId];

	const copyToClipboard = () => {
		navigator.clipboard.writeText(process.env.REACT_APP_LIIINGO_WEBAPP_URL + '/topic/' + topicId);
		setCopySuccess(true);
	};

	return (
		<>
			<LiiingoContextMenu
				id="editcontextMenu1"
				options={[
					{
						text: 'Copy Link',
						handleClick: copyToClipboard,
					},
					{
						text: 'Download PNG',
						handleClick: () => downloadImage(topicId, selectedTopic.name[0].name ,'png'),
					},
					{
						text: 'Download SVG',
						handleClick: () => downloadImage(topicId, selectedTopic.name[0].name ,'svg'),
					},
				]}
			>
				{children}
			</LiiingoContextMenu>
			<LiiingoSuccessSnackbar
				open={copySuccess}
				text="Link copied successfully"
				onClose={() => setCopySuccess(false)}
			/>
		</>
	);
};
