/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, FormControl, Grid, Paper, Select, Slider, Switch, TextField, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CropSquare, Eco, LocalFlorist, RadioButtonUnchecked } from '@material-ui/icons';
import { Circle, CircleOutlined, Diamond, RoundedCorner, Square } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as lodash from 'lodash';
import { useCallback, useState } from 'react';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { qrCodePreview } from '../../../store/api-client';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { _selectedLocation, updateLocation } from '../../../store/slices/locationSlice';
import { _selectedTopic } from '../../../store/slices/topicSlice';
import { colors } from '../../../theme/palette';
import { FlatButton } from '../../Buttons/FlatButton';
import { LiiingoTempDrawer } from '../../LiiingoTempDrawer';
import { CustomImageUploading } from './CustomImageUploading';
import { QRColorPickerPopover } from './QRColorPickerPopover';

import BrushIcon from '@material-ui/icons/Brush';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import FavoriteIcon from '@material-ui/icons/Favorite';
import GradientIcon from '@material-ui/icons/Gradient';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 1 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		editorBox: {
			display: 'flex',
			flexDirection: 'column',
			width: 547,
			height: '100%',
			marginLeft: 10,
			marginRight: 10,
			overflow: 'hidden',
		},
		buttonBox: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			height: 65,
			background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)),rgb(31, 32, 33)',

			marginLeft: -10,
			marginRight: -10,
			position: 'sticky',
			bottom: 0,
		},
		buttonBoxFocused: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			height: 65,
			background: 'blue',

			marginLeft: -10,
			marginRight: -10,
			position: 'sticky',
			bottom: 0,
		},

		button: {
			height: 40,
			marginRight: 30,
		},
		previewBox: {
			display: 'flex',
			justifyContent: 'center',
			margin: 'auto',
		},
		previewWrapper: {
			display: 'flex',
			jusiifyContent: 'center',
			alignItems: 'center',
			background: 'white',
			paddingBottom: 10,
			order: 2,
		},
		desc: {
			marginBottom: 16,
			color: '#181B20',
		},
		colorBox: {
			display: 'flex',
			justifyContent: 'space-between',
			marginTop: 10,
			gap: '16px',
		},
		menu: {
			display: 'flex',
			position: 'relative',
			left: 0,
			top: 0,
			minHeight: '90%',
			justifyContent: 'space-between',
			flexDirection: 'column',
		},
		optionBox: {
			overflowY: 'scroll',
			height: '90% ',
			width: '100%',
		},
		alert: {
			margin: 8,
			backgroundColor: colors.hotPurpleAccent20,
		},
		icon: {
			color: colors.hotPurpleAccent,
		},
		dismissableTip: {
			marginTop: 40,
		},
		link: {
			color: 'blue',
			textDecoration: 'none',
			fontWeight: 700,
		},
		scrollableBox: {
			gap: '15px',
			padding: '16px 16px',
			display: 'flex',
			flexDirection: 'column',
		},

		heading: {
			paddingLeft: 5,
			fontSize: theme.typography.pxToRem(17),
		},
		title: {
			textAlign: 'left',
		},
		buttonContainer: {
			display: 'flex',
			flexDirection: 'row',
			gap: theme.spacing(2),
			flexWrap: 'nowrap',
			overflowX: 'auto',
			padding: theme.spacing(1),
			'&::-webkit-scrollbar': {
				display: 'none',
			},
			'-ms-overflow-style': 'none',
			'scrollbar-width': 'none',
		},
		customizeButton: {
			borderRadius: '8px',
			minWidth: 'auto',
			padding: theme.spacing(1, 2),
			color: '#1976d2',
			backgroundColor: theme.palette.background.paper,
			'&:hover': {
				backgroundColor: '#1976d2',
			},
		},
		customizeButtonFocused: {
			borderRadius: '8px',
			minWidth: 'auto',
			padding: theme.spacing(1, 2),
			backgroundColor: '#1976d2',
			color: theme.palette.primary.contrastText,
			'&:hover': {
				backgroundColor: '#1976d2',
			},
		},

		container: {
			display: 'grid',
			gridTemplateColumns: 'repeat(12, 1fr)',
			gridGap: theme.spacing(3),
		},
		paper: {
			textAlign: 'center',
			whiteSpace: 'nowrap',
			gap: '8px',
			margin: theme.spacing(1),
			overflow: 'auto',
		},
		sliderDisplayInfo: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			width: '100%',
			paddingTop: 10,
		},
		dropZone: {
			width: '100%',
			padding: 15,
			margin: 'auto',
			border: '1px solid #ccc',
			borderRadius: '.25rem',
			cursor: 'pointer',
		},
		marginTopBottom: {
			marginBottom: 20,
			marginTop: 20,
		},
		dropDownSelect: {
			width: '100%',
			marginBottom: 40,
		},
		frameDisplayBox: {
			width: 70,
			height: 70,
			padding: 10,
			cursor: 'pointer',
		},

		frameDisplayBoxFocused: {
			width: 70,
			height: 70,
			padding: 10,
			cursor: 'hand',
			background: 'blue',
			borderRadius: '5%',
		},
		drawerPaper: {
			width: '100%',
			marginLeft: 10,
			marginRight: 10,
		},
		switch: {
			textAlign: 'justify',
		},
		fade: {
			animation: 'fade 1s ease .5s both',
		},
	})
);

export type QREditorProps = {
	open: boolean;
	primary: string;
	secondary: string;
	setOpen: (open: boolean) => void;
};

export const QREditor = (props: QREditorProps) => {
	const { open, setOpen } = {
		...props,
	};
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const location = useAppSelector(_selectedLocation);
	const selectedTopic = useAppSelector(_selectedTopic);
	const classes = useStyles();
	const [isDrag, setIsDrag] = useState(false);
	const [value, setValue] = useState(0);
	const [qrprops, setQRProps] = useState({ ...location?.qrcodeProperties, fileType: 'svg' });
	const [qrCode, setQrCode] = useState(process.env.REACT_APP_LIIINGO_URL + '/exhibit/qr?id=' + selectedTopic?.id);
	const saveQrProps = bindActionCreators(updateLocation, dispatch);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const handleSave = () => {
		saveQrProps({ qrcodeProperties: qrprops });
		setOpen(false);
	};

	const handleDragStart = () => {
		if (!isDrag) setIsDrag(true);
	};

	const handleDragStop = () => {
		if (isDrag) setIsDrag(false);
	};

	window.addEventListener('dragover', handleDragStart, false);
	window.addEventListener('dragleave', handleDragStop, false);
	window.addEventListener('drop', handleDragStop, false);

	const handleBgImgTransparencyChange = (event, newValue) => {
		setQRProps({ ...qrprops, qr_code_background_transparency: newValue });
	};

	const handleBgColorTransparencyChange = (event, newValue) => {
		setQRProps({ ...qrprops, background_color_transparency: newValue });
	};

	const handleLogoSizeChange = (event, newValue) => {
		setQRProps({ ...qrprops, qr_code_logo_size: newValue });
	};

	const handleEcc = (e) => {
		setQRProps({ ...qrprops, ecc: e.target.value });
	};

	const makePreview = useCallback(
		lodash.debounce(
			(qrprops) => {
				qrCodePreview(qrprops).then((response) => setQrCode(response.data));
			},
			1000,
			{ trailing: true }
		),
		[]
	);

	useDidMountEffect(() => {
		makePreview(qrprops);
	}, [qrprops]);

	return (
		<LiiingoTempDrawer title="Design Your QR Code" id="QREditor" open={open} setOpen={setOpen}>
			<Box className={classes.editorBox}>
				<Typography className={classes.desc} variant="body2">
					Add a logo, colors, and much more to make your QR code recognizable to your audience.{' '}
					<a
						className={classes.link}
						href="https://support.liiingo.com/question-category/share"
						target="_blank"
						rel=" noreferrer"
					>
						Learn more in our Help Center.
					</a>
				</Typography>

				<Box className={classes.menu}>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							width: '100%',
							backgroundColor: 'background.paper',
							paddingBottom: '16px',
							zIndex: 2,
						}}
					>
						<img
							width={150}
							height={150}
							src={
								qrCode.startsWith('http')
									? qrCode
									: 'data:image/svg+xml;utf8,' + encodeURIComponent(qrCode)
							}
							alt="QR Code"
							className={classes.fade}
						></img>
					</Box>

					<AppBar position="static">
						<Tabs
							value={value}
							onChange={handleChange}
							indicatorColor="primary"
							textColor="inherit"
							variant="fullWidth"
							aria-label="full width tabs example"
							sx={{
								'& .MuiTabs-indicator': {
									backgroundColor: '#ffffff',
								},
							}}
						>
							<Tab label="Style" {...a11yProps(0)} />
							<Tab label="Colors" {...a11yProps(1)} />
							<Tab label="Frame" {...a11yProps(2)} />
							<Tab label="Branding" {...a11yProps(3)} />
						</Tabs>
					</AppBar>
					<Box component="div" className={classes.optionBox}>
						<Box className={classes.scrollableBox}>
							<Box sx={{ bgcolor: 'background.paper' }}>
								<TabPanel value={value} index={0} dir={theme.direction}>
									<Typography className={classes.title}>
										<i className="fa-solid fa-mortar-pestle"></i> Style
									</Typography>
									<Box>
										<Grid container>
											<Grid item xs={12}>
												<Paper className={classes.paper} elevation={0}>
													<div className={classes.buttonContainer}>
														<Tooltip title="Square" placement="top">
															<FlatButton
																className={
																	qrprops?.style === 'square'
																		? classes.customizeButtonFocused
																		: classes.customizeButton
																}
																variant="contained"
																color="secondary"
																onClick={() => {
																	setQRProps({ ...qrprops, style: 'square' });
																}}
															>
																<Square style={{ marginRight: '8px' }} />
															</FlatButton>
														</Tooltip>

														<Tooltip title="Dot" placement="top">
															<FlatButton
																className={
																	qrprops?.style === 'dot'
																		? classes.customizeButtonFocused
																		: classes.customizeButton
																}
																variant="contained"
																color="secondary"
																onClick={() => {
																	setQRProps({ ...qrprops, style: 'dot' });
																}}
															>
																<Circle style={{ marginRight: '8px' }} />
															</FlatButton>
														</Tooltip>

														<Tooltip title="Rounded" placement="top">
															<FlatButton
																className={
																	qrprops?.style === 'round'
																		? classes.customizeButtonFocused
																		: classes.customizeButton
																}
																variant="contained"
																color="secondary"
																onClick={() => {
																	setQRProps({ ...qrprops, style: 'round' });
																}}
															>
																<RoundedCorner style={{ marginRight: '8px' }} />
															</FlatButton>
														</Tooltip>

														<Tooltip title="Heart" placement="top">
															<FlatButton
																className={
																	qrprops?.style === 'heart'
																		? classes.customizeButtonFocused
																		: classes.customizeButton
																}
																variant="contained"
																color="secondary"
																onClick={() => {
																	setQRProps({ ...qrprops, style: 'heart' });
																}}
															>
																<FavoriteIcon style={{ marginRight: '8px' }} />
															</FlatButton>
														</Tooltip>

														<Tooltip title="Diamond" placement="top">
															<FlatButton
																className={
																	qrprops?.style === 'diamond'
																		? classes.customizeButtonFocused
																		: classes.customizeButton
																}
																variant="contained"
																color="secondary"
																onClick={() => {
																	setQRProps({ ...qrprops, style: 'diamond' });
																}}
															>
																<Diamond style={{ marginRight: '8px' }} />
															</FlatButton>
														</Tooltip>
													</div>
												</Paper>
											</Grid>
										</Grid>
									</Box>
									<Typography className={classes.title}>
										<i className="fa-solid fa-circle"></i> Inner eye style
									</Typography>
									<Box>
										<Grid container spacing={3}>
											<Grid item xs={12}>
												<Paper className={classes.paper} elevation={0}>
													<div className={classes.buttonContainer}>
														<Tooltip title="Square" placement="top">
															<FlatButton
																className={
																	qrprops?.inner_eye_style === 'square'
																		? classes.customizeButtonFocused
																		: classes.customizeButton
																}
																variant="contained"
																color="secondary"
																onClick={() => {
																	setQRProps({
																		...qrprops,
																		inner_eye_style: 'square',
																	});
																}}
															>
																<CropSquare style={{ marginRight: '8px' }} />
															</FlatButton>
														</Tooltip>
														<Tooltip title="Dot" placement="top">
															<FlatButton
																className={
																	qrprops?.inner_eye_style === 'dot'
																		? classes.customizeButtonFocused
																		: classes.customizeButton
																}
																variant="contained"
																color="secondary"
																onClick={() => {
																	setQRProps({ ...qrprops, inner_eye_style: 'dot' });
																}}
															>
																<CircleOutlined style={{ marginRight: '8px' }} />
															</FlatButton>
														</Tooltip>

														<Tooltip title="Rounded" placement="top">
															<FlatButton
																className={
																	qrprops?.inner_eye_style === 'rounded'
																		? classes.customizeButtonFocused
																		: classes.customizeButton
																}
																variant="contained"
																color="secondary"
																onClick={() => {
																	setQRProps({
																		...qrprops,
																		inner_eye_style: 'rounded',
																	});
																}}
															>
																<RoundedCorner style={{ marginRight: '8px' }} />
															</FlatButton>
														</Tooltip>

														<Tooltip title="Flower" placement="top">
															<FlatButton
																className={
																	qrprops?.inner_eye_style === 'flower'
																		? classes.customizeButtonFocused
																		: classes.customizeButton
																}
																variant="contained"
																color="secondary"
																onClick={() => {
																	setQRProps({
																		...qrprops,
																		inner_eye_style: 'flower',
																	});
																}}
															>
																<LocalFlorist style={{ marginRight: '8px' }} />
															</FlatButton>
														</Tooltip>

														<Tooltip title="Leaf" placement="top">
															<FlatButton
																className={
																	qrprops?.inner_eye_style === 'leaf'
																		? classes.customizeButtonFocused
																		: classes.customizeButton
																}
																variant="contained"
																color="secondary"
																onClick={() => {
																	setQRProps({ ...qrprops, inner_eye_style: 'leaf' });
																}}
															>
																<Eco style={{ marginRight: '8px' }} />
															</FlatButton>
														</Tooltip>

														<Tooltip title="Diamond" placement="top">
															<FlatButton
																className={
																	qrprops?.inner_eye_style === 'diamond'
																		? classes.customizeButtonFocused
																		: classes.customizeButton
																}
																variant="contained"
																color="secondary"
																onClick={() => {
																	setQRProps({
																		...qrprops,
																		inner_eye_style: 'diamond',
																	});
																}}
															>
																<Diamond style={{ marginRight: '8px' }} />
															</FlatButton>
														</Tooltip>
													</div>
												</Paper>
											</Grid>
										</Grid>
									</Box>
									<Typography className={classes.title}>
										<i className="fa-solid fa-circle-dot"></i> Outer eye style
									</Typography>
									<Box>
										<Grid container spacing={3}>
											<Grid item xs={12}>
												<Paper className={classes.paper} elevation={0}>
													<div className={classes.buttonContainer}>
														<Tooltip title="Square" placement="top">
															<FlatButton
																className={
																	qrprops?.outer_eye_style === 'square'
																		? classes.customizeButtonFocused
																		: classes.customizeButton
																}
																variant="contained"
																color="secondary"
																onClick={() => {
																	setQRProps({
																		...qrprops,
																		outer_eye_style: 'square',
																	});
																}}
															>
																<CropSquare style={{ marginRight: '8px' }} />
															</FlatButton>
														</Tooltip>

														<Tooltip title="Circle" placement="top">
															<FlatButton
																className={
																	qrprops?.outer_eye_style === 'circle'
																		? classes.customizeButtonFocused
																		: classes.customizeButton
																}
																variant="contained"
																color="secondary"
																onClick={() => {
																	setQRProps({
																		...qrprops,
																		outer_eye_style: 'circle',
																	});
																}}
															>
																<Circle style={{ marginRight: '8px' }} />
															</FlatButton>
														</Tooltip>

														<Tooltip title="Rounded" placement="top">
															<FlatButton
																className={
																	qrprops?.outer_eye_style === 'rounded'
																		? classes.customizeButtonFocused
																		: classes.customizeButton
																}
																variant="contained"
																color="secondary"
																onClick={() => {
																	setQRProps({
																		...qrprops,
																		outer_eye_style: 'rounded',
																	});
																}}
															>
																<RadioButtonUnchecked style={{ marginRight: '8px' }} />
															</FlatButton>
														</Tooltip>

														<Tooltip title="Flower" placement="top">
															<FlatButton
																className={
																	qrprops?.outer_eye_style === 'flower'
																		? classes.customizeButtonFocused
																		: classes.customizeButton
																}
																variant="contained"
																color="secondary"
																onClick={() => {
																	setQRProps({
																		...qrprops,
																		outer_eye_style: 'flower',
																	});
																}}
															>
																<LocalFlorist style={{ marginRight: '8px' }} />
															</FlatButton>
														</Tooltip>

														<Tooltip title="Leaf" placement="top">
															<FlatButton
																className={
																	qrprops?.outer_eye_style === 'leaf'
																		? classes.customizeButtonFocused
																		: classes.customizeButton
																}
																variant="contained"
																color="secondary"
																onClick={() => {
																	setQRProps({ ...qrprops, outer_eye_style: 'leaf' });
																}}
															>
																<Eco style={{ marginRight: '8px' }} />
															</FlatButton>
														</Tooltip>
													</div>
												</Paper>
											</Grid>
										</Grid>
									</Box>

									<Typography>
										<i className="fa-solid fa-circle-dot"></i> Options
									</Typography>
									<Divider style={{ marginBottom: '8px', height: '3px' }} />
									<Box
										style={{
											width: '100%',
											display: 'flex',
											flexDirection: 'row',
											gap: '20px',
											justifyContent: 'space-between',
										}}
									>
										<Box style={{ flex: 1 }}>
											<Box className={classes.marginTopBottom}>
												<Typography style={{ width: 'auto', textAlign: 'left' }}>
													<i className="fa-solid fa-maximize"></i> Size
												</Typography>
											</Box>

											<TextField
												size="small"
												fullWidth
												id="size"
												type="number"
												name="size"
												placeholder='Enter overall size here, e.g "500"'
												value={qrprops?.size ? qrprops?.size : ''}
												onChange={(e) => {
													setQRProps({ ...qrprops, size: Number(e.target.value) });
												}}
												variant="outlined"
												InputLabelProps={{
													shrink: true,
													style: {
														marginTop: 5,
													},
												}}
											/>
										</Box>

										<Box style={{ flex: 1 }}>
											<Box className={classes.marginTopBottom}>
												<Typography style={{ width: 'auto', textAlign: 'left' }}>
													<i className="fa-solid fa-expand"></i> Margin
												</Typography>
											</Box>
											<TextField
												size="small"
												fullWidth
												id="size"
												type="number"
												name="size"
												placeholder="Enter margin size here"
												value={qrprops?.margin ? qrprops?.margin : ''}
												variant="outlined"
												onChange={(e) => {
													setQRProps({ ...qrprops, margin: Number(e.target.value) });
												}}
												InputLabelProps={{
													shrink: true,
													style: {
														marginTop: 5,
													},
												}}
											/>
										</Box>

										<Box style={{ flex: 1 }}>
											<Box className={classes.marginTopBottom}>
												<Typography style={{ width: '100%', textAlign: 'left' }}>
													<i className="fa-solid fa-check"></i> ECC
												</Typography>
											</Box>

											<Select
												fullWidth
												style={{ height: '40px' }}
												name="errorCorrectionCapability"
												value={qrprops?.ecc ? qrprops?.ecc : 'L'}
												label=" Error correction capability "
												variant="outlined"
												onChange={handleEcc}
												MenuProps={{
													PaperProps: {
														style: {
															backgroundColor: '#ffffff',
															border: '1px solid rgba(0, 0, 0, 0.12)',
															borderRadius: '20px',
															color: '#000000',
														},
													},
												}}
											>
												<MenuItem value="L">Low</MenuItem>
												<MenuItem value="M">Medium</MenuItem>
												<MenuItem value="Q">Quartile</MenuItem>
												<MenuItem value="H">High</MenuItem>
											</Select>
										</Box>
									</Box>
								</TabPanel>

								<TabPanel value={value} index={1} dir={theme.direction}>
									<Box>
										<Typography>Foreground type</Typography>
										<Divider style={{ marginBottom: '8px', height: '3px' }} />

										<Box style={{ width: '100%' }}>
											<Grid container spacing={3}>
												<Grid item xs={6}>
													<Paper className={classes.paper} elevation={0}>
														<FlatButton
															className={
																qrprops?.foreground_type !== 'gradient'
																	? classes.customizeButtonFocused
																	: classes.customizeButton
															}
															variant="contained"
															color="secondary"
															onClick={(e) => {
																setQRProps({ ...qrprops, foreground_type: 'color' });
															}}
														>
															<ColorLensIcon style={{ marginRight: '8px' }} />
															Color
														</FlatButton>
													</Paper>
												</Grid>
												<Grid item xs={6}>
													<Paper className={classes.paper} elevation={0}>
														<FlatButton
															className={
																qrprops?.foreground_type &&
																qrprops?.foreground_type === 'gradient'
																	? classes.customizeButtonFocused
																	: classes.customizeButton
															}
															variant="contained"
															color="secondary"
															onClick={(e) => {
																setQRProps({
																	...qrprops,
																	foreground_type: 'gradient',
																	foreground_gradient_style: 'horizontal',
																	foreground_gradient_one:
																		qrprops.foreground_gradient_one ?? '#000000',
																	foreground_gradient_two: '#000000',
																});
															}}
														>
															<GradientIcon style={{ marginRight: '8px' }} />
															Gradient
														</FlatButton>
													</Paper>
												</Grid>
											</Grid>

											<Box>
												{qrprops?.foreground_type && qrprops?.foreground_type === 'gradient' && (
													<>
														<Box className={classes.dropDownSelect}>
															<Box className={classes.marginTopBottom}>
																<Typography
																	style={{ width: '100%', textAlign: 'left' }}
																>
																	<BrushIcon style={{ marginRight: '8px' }} /> style
																</Typography>
															</Box>
															<Select
																name="selectedGradientStyle"
																fullWidth
																variant="outlined"
																style={{ height: '40px' }}
																value={
																	qrprops?.foreground_gradient_style
																		? qrprops?.foreground_gradient_style
																		: 'horizontal'
																}
																label="Foreground gradient style"
																onChange={(e) => {
																	setQRProps({
																		...qrprops,
																		foreground_gradient_style: String(
																			e.target.value
																		),
																	});
																}}
																MenuProps={{
																	PaperProps: {
																		style: {
																			backgroundColor: '#ffffff',
																			border: '1px solid rgba(0, 0, 0, 0.12)',
																			borderRadius: '20px',
																			color: '#000000',
																		},
																	},
																}}
															>
																<MenuItem value="vertical">Vertical</MenuItem>
																<MenuItem value="horizontal">Horizontal</MenuItem>
																<MenuItem value="diagonal">Diagonal</MenuItem>
																<MenuItem value="inverse_diagonal">
																	Inverse Diagonal
																</MenuItem>
																<MenuItem value="radial">Radial</MenuItem>
															</Select>
														</Box>
														<Box
															className={(classes.colorBox, classes.marginTopBottom)}
															style={{ display: 'flex', gap: '16px' }}
														>
															{/** Color Pickers */}

															<QRColorPickerPopover
																setColor={(color) => {
																	setQRProps({
																		...qrprops,
																		foreground_gradient_one: color,
																	});
																}}
																setError={(e) => {
																	console.log(e);
																}}
																id="primaryColor"
																name="customQrCodeColors.primary"
																label="Primary Color"
																color={qrprops.foreground_gradient_one ?? '#000000'}
															/>
															<QRColorPickerPopover
																setColor={(color) => {
																	setQRProps({
																		...qrprops,
																		foreground_gradient_two: color,
																	});
																}}
																setError={(e) => {
																	console.log(e);
																}}
																id="secondaryColor"
																name="customQrCodeColors.secondary"
																label="Secondary Color"
																color={qrprops.foreground_gradient_two ?? '#000000'}
															/>
														</Box>
													</>
												)}

												{qrprops?.foreground_type && qrprops?.foreground_type === 'color' && (
													<Box className={(classes.colorBox, classes.marginTopBottom)}>
														<QRColorPickerPopover
															setColor={(color) => {
																setQRProps({
																	...qrprops,
																	foreground_color: color,
																});
															}}
															setError={(e) => {
																console.log(e);
															}}
															id="foreground_color"
															name="foreground_color"
															label="Foreground Color"
															color={qrprops?.foreground_color ?? '#FFFFFF'}
														/>
													</Box>
												)}

												<Typography>Background Options</Typography>
												<Divider style={{ marginBottom: '8px', height: '3px' }} />
												<Box style={{ display: 'flex', gap: '24px' }}>
													<Box style={{ flex: 1 }}>
														<Box className={(classes.colorBox, classes.marginTopBottom)}>
															{/** Background Color Picker */}
															<QRColorPickerPopover
																setColor={(color) => {
																	setQRProps({
																		...qrprops,
																		background_color: color,
																	});
																}}
																setError={(e) => {
																	console.log(e);
																}}
																id="backgroundColor"
																name="customQrCodeColors.bgColor"
																label="Background Color"
																color={qrprops?.background_color ?? '#FFFFFF'}
															/>
														</Box>
													</Box>

													<Box
														style={{
															flex: 1,
															alignItems: 'center',
															justifyContent: 'center',
															alignContent: 'center',
														}}
													>
														<Typography style={{ textAlign: 'center' }}>
															Background Transparency
														</Typography>

														<Box
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
																gap: '8px',
															}}
														>
															<Slider
																value={
																	Number(qrprops?.background_color_transparency) ?? 0
																}
																onChange={handleBgColorTransparencyChange}
															/>
															<span>{qrprops?.background_color_transparency ?? 0}%</span>
														</Box>
													</Box>
												</Box>

												<Box className={(classes.marginTopBottom, classes.switch)}>
													<Typography style={{ textAlign: 'center' }}>
														Custom eyes color
													</Typography>
													<Divider style={{ height: '3px' }} />
													<Switch
														checked={qrprops?.custom_eyes_color === 'on' ? true : false}
														name="pickEyeColor"
														onChange={(e) => {
															if (e.target.checked) {
																setQRProps({
																	...qrprops,
																	custom_eyes_color: 'on',
																});
															} else {
																setQRProps({
																	...qrprops,
																	custom_eyes_color: 'off',
																	eyes_inner_color: '#000000',
																	eyes_outer_color: '#000000',
																});
															}
														}}
														inputProps={{ 'aria-label': 'eye color switch' }}
													/>
													{qrprops?.custom_eyes_color === 'on' && (
														<Box
															className={(classes.colorBox, classes.marginTopBottom)}
															style={{ display: 'flex', gap: '16px' }}
														>
															{/**
															 * Eye Color Pickers
															 */}
															<QRColorPickerPopover
																setColor={(color) => {
																	setQRProps({ ...qrprops, eyes_inner_color: color });
																}}
																setError={(e) => {
																	console.log(e);
																}}
																id="innerEyeColor"
																name="qrprops?.eyes_inner_color"
																label="Inner Eye Color"
																color={qrprops?.eyes_inner_color ?? '#000000'}
															/>
															<QRColorPickerPopover
																setColor={(color) => {
																	setQRProps({ ...qrprops, eyes_outer_color: color });
																}}
																setError={(e) => {
																	console.log(e);
																}}
																id="outerEyeColor"
																name="customQrCodeColors.secondaryEyeColor"
																label="Outer Eye Color"
																color={qrprops?.eyes_outer_color ?? '#000000'}
															/>
														</Box>
													)}
												</Box>
											</Box>
										</Box>
									</Box>
								</TabPanel>

								<TabPanel value={value} index={2} dir={theme.direction}>
									<Box style={{ width: '100%' }}>
										{/* GROUP OF FRAMES GO HERE */}
										<Grid container spacing={1}>
											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === ''
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															delete qrprops.frame;
															setQRProps({ ...qrprops });
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 100'%3E%3Ctext x='150' y='60' font-family='Arial, sans-serif' font-size='36' text-anchor='middle' fill='%23333333'%3ENo Frame%3C/text%3E%3C/svg%3E"
															alt="'No Frame'"
														/>
													</div>
												</Paper>
											</Grid>
											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === 'round_bottom_text'
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															setQRProps({ ...qrprops, frame: 'round_bottom_text' });
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg width='100%' height='50' viewBox='0 0 24 28' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='scale(1) translate(0 0)'%3E%3Cpath d='M22.7,0H1.3C0.6,0,0,0.6,0,1.3v25.3C0,27.4,0.6,28,1.3,28h21.3c0.7,0,1.3-0.6,1.3-1.3V1.3C24,0.6,23.4,0,22.7,0 z M23,22c0,0.6-0.5,1-1,1H2c-0.6,0-1-0.5-1-1V2c0-0.6,0.5-1,1-1h20c0.6,0,1,0.5,1,1V22z'/%3E%3C/g%3E%3C/svg%3E"
															alt="Round Bottom Text"
														/>
													</div>
												</Paper>
											</Grid>
											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === 'round_top_text'
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															setQRProps({ ...qrprops, frame: 'round_top_text' });
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg width='100%' height='50' viewBox='0 0 24 28' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='scale(1) translate(0 0)'%3E%3Cpath d='M1.3,28L22.6,28c0.7,0,1.3-0.6,1.3-1.3L24,1.4c0-0.7-0.6-1.3-1.3-1.3L1.4,0C0.7,0,0.1,0.6,0,1.3L0,26.6 C-0.1,27.4,0.5,28,1.3,28z M1,6c0-0.6,0.5-1,1-1L22,5c0.6,0,1,0.5,1,1L23,26c0,0.6-0.5,1-1,1L2,27c-0.6,0-1-0.5-1-1L1,6z'/%3E%3C/g%3E%3C/svg%3E"
															alt="Round Top Text"
														/>
													</div>
												</Paper>
											</Grid>
											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === 'tooltip_bottom_text'
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															setQRProps({
																...qrprops,
																frame: 'tooltip_bottom_text',
															});
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg width='100%' height='50' viewBox='0 0 24 30' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='scale(1) translate(0 0)'%3E%3Cpath d='M1.3,24l21.3,0c0.7,0,1.3-0.6,1.3-1.3l0-21.3C24,0.6,23.4,0,22.7,0L1.3,0C0.6,0,0,0.6,0,1.3l0,21.3 C0,23.4,0.6,24,1.3,24z M1,2c0-0.6,0.5-1,1-1l20,0c0.6,0,1,0.5,1,1v20c0,0.6-0.5,1-1,1L2,23c-0.6,0-1-0.5-1-1V2z'/%3E%3Cpath d='M1,30h22c0.5,0,1-0.4,1-1v-3c0-0.5-0.4-1-1-1H13l-1-1l-1,1H1c-0.5,0-1,0.4-1,1v3C0,29.6,0.4,30,1,30z'/%3E%3C/g%3E%3C/svg%3E"
															alt="Tooltip Bottom Text"
														/>
													</div>
												</Paper>
											</Grid>
											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === 'tooltip_top_text'
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															setQRProps({ ...qrprops, frame: 'tooltip_top_text' });
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg width='100%' height='50' viewBox='0 0 24 30' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='scale(1) translate(0 0)'%3E%3Cpath d='M22.7,6L1.3,6C0.6,6,0,6.6,0,7.3l0,21.3C0,29.4,0.6,30,1.3,30l21.3,0c0.7,0,1.3-0.6,1.3-1.3l0-21.3 C24,6.6,23.4,6,22.7,6z M23,28c0,0.6-0.5,1-1,1L2,29c-0.6,0-1-0.5-1-1V8c0-0.6,0.5-1,1-1l20,0c0.6,0,1,0.5,1,1V28z'/%3E%3Cpath d='M23,0H1C0.4,0,0,0.4,0,1v3c0,0.5,0.4,1,1,1h10l1,1l1-1h10c0.5,0,1-0.4,1-1V1C24,0.4,23.6,0,23,0z'/%3E%3C/g%3E%3C/svg%3E"
															alt="Tooltip Top Text"
														/>
													</div>
												</Paper>
											</Grid>

											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === 'ribbon_bottom_text'
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															setQRProps({ ...qrprops, frame: 'ribbon_bottom_text' });
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg width='100%' height='50' viewBox='0 0 24 26.4' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='scale(1) translate(0 0)'%3E%3Cpath d='M24,21h-1.7V1.7H1.7V21H0l1,2l-1,2h1v2h22v-2h1l-1-2L24,21z M2,2h20v19v1H2v-1V2z'/%3E%3C/g%3E%3C/svg%3E"
															alt="Ribbon Bottom Text"
														/>
													</div>
												</Paper>
											</Grid>
											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === 'ribbon_top_text'
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															setQRProps({ ...qrprops, frame: 'ribbon_top_text' });
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg width='100%' height='50' viewBox='0 0 24 26.4' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='scale(1) translate(0 0)'%3E%3Cpath d='M0,6h1.7v19.3h20.7V6H24l-1-2l1-2h-1V0H1v2H0l1,2L0,6z M22,25H2V6V5h20v1V25z'/%3E%3C/g%3E%3C/svg%3E"
															alt="Ribbon Top Text"
															style={{ objectFit: 'contain' }}
														/>
													</div>
												</Paper>
											</Grid>
											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === 'tooltip_snap_bottom_text'
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															setQRProps({
																...qrprops,
																frame: 'tooltip_snap_bottom_text',
															});
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg width='100%' height='50' viewBox='0 0 500 705' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='scale(1) translate(0 0)'%3E%3Cg transform='matrix(2.399191, 0, 0, 2.399191, -109.938606, -7.37865)' style=''%3E%3Cpath d='M 225.107 7.755 L 244.497 7.755 C 247.258 7.755 249.497 9.994 249.497 12.755 L 249.497 31.485 L 254.227 31.485 L 254.227 12.755 C 254.227 7.409 249.893 3.075 244.547 3.075 L 225.107 3.075 L 225.107 7.755 Z'/%3E%3Cpath d='M 50.393 31.485 L 50.393 12.755 C 50.393 9.994 52.632 7.755 55.393 7.755 L 73.463 7.755 L 73.463 3.075 L 55.393 3.075 C 50.047 3.075 45.713 7.409 45.713 12.755 L 45.713 31.485 L 50.393 31.485 Z'/%3E%3Cpath d='M 73.573 206.799 L 55.503 206.799 C 52.742 206.799 50.503 204.56 50.503 201.799 L 50.503 183.069 L 45.823 183.069 L 45.823 201.799 C 45.823 207.145 50.157 211.479 55.503 211.479 L 73.573 211.479 L 73.573 206.799 Z'/%3E%3Cpath d='M 249.547 183.069 L 249.547 201.799 C 249.547 204.56 247.308 206.799 244.547 206.799 L 225.157 206.799 L 225.157 211.479 L 244.547 211.479 C 249.893 211.479 254.227 207.145 254.227 201.799 L 254.227 183.069 L 249.547 183.069 Z'/%3E%3Cpath d='M 244.68 214.77 L 55.38 214.77 C 50.272 214.77 46.13 218.911 46.13 224.02 L 46.13 265.58 C 46.125 270.692 50.268 274.84 55.38 274.84 L 125.93 274.84 C 126.544 274.837 127.134 275.078 127.57 275.51 L 148.31 296.25 C 149.219 297.15 150.682 297.15 151.59 296.25 L 172.34 275.51 C 172.774 275.075 173.366 274.833 173.98 274.84 L 244.56 274.84 C 249.673 274.84 253.816 270.692 253.81 265.58 L 253.81 224.02 C 253.849 218.942 249.758 214.797 244.68 214.77 Z' style='transform-box: fill-box; transform-origin: 50%25 50%25;' transform='matrix(-1, 0, 0, -1, -0.000006, -0.000011)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"
															alt="Tooltip Snap Bottom Text"
															style={{ overflow: 'hidden' }}
														/>
													</div>
												</Paper>
											</Grid>
											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === 'tooltip_snap_top_text'
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															setQRProps({
																...qrprops,
																frame: 'tooltip_snap_top_text',
															});
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg width='100%' height='50' viewBox='0 0 500 705' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='scale(1) translate(0 0)'%3E%3Cg transform='matrix(2.399191, 0, 0, 2.399191, -109.938606, -7.37865)' style=''%3E%3Cpath d='M224.88,93.12h19.39a5,5,0,0,1,5,5v18.73H254V98.12a9.68,9.68,0,0,0-9.68-9.68H224.88Z'/%3E%3Cpath d='M50.73,116.85V98.12a5,5,0,0,1,5-5H73.8V88.44H55.73a9.68,9.68,0,0,0-9.68,9.68v18.73Z'/%3E%3Cpath d='M73.8,291.67H55.73a5,5,0,0,1-5-5V267.94H46.05v18.73a9.68,9.68,0,0,0,9.68,9.68H73.8Z'/%3E%3Cpath d='M249.27,267.94v18.73a5,5,0,0,1-5,5H224.88v4.68h19.39a9.68,9.68,0,0,0,9.68-9.68V267.94Z'/%3E%3Cpath d='M244.75,3.65H55.45A9.25,9.25,0,0,0,46.2,12.9V54.46a9.25,9.25,0,0,0,9.25,9.26H126a2.32,2.32,0,0,1,1.64.67l20.74,20.74a2.33,2.33,0,0,0,3.28,0l20.75-20.74a2.28,2.28,0,0,1,1.64-.67h70.58a9.25,9.25,0,0,0,9.25-9.26V12.9A9.18,9.18,0,0,0,244.75,3.65Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"
															alt="Tooltip Snap Top Text"
														/>
													</div>
												</Paper>
											</Grid>
										</Grid>
										<Box className={classes.marginTopBottom}>
											<Box className={classes.marginTopBottom}>
												<Typography style={{ width: 'auto', textAlign: 'left' }}>
													<i className="fa-solid fa-signature"></i> Frame text
												</Typography>
											</Box>
											<TextField
												fullWidth
												id="outlined-size-small"
												placeholder="Enter text here"
												variant="outlined"
												size="small"
												value={qrprops.frameText}
												onChange={(e) => {
													setQRProps({
														...qrprops,
														frame_text: e.target.value,
														frame_text_size: qrprops?.frame_text_size ?? 0,
													});
												}}
												InputLabelProps={{
													shrink: true,
													style: {
														marginTop: 5,
													},
												}}
											/>
										</Box>
										<Box className={classes.marginTopBottom}>
											<Grid container spacing={2}>
												<Grid item xs={6}>
													<Typography style={{ width: 'auto', textAlign: 'left' }}>
														<i className="fa-solid fa-arrows-up-down"></i> Size
													</Typography>
													<FormControl fullWidth>
														<Select
															value={qrprops?.frame_text_size || 0}
															onChange={(e) => {
																setQRProps({
																	...qrprops,
																	frame_text_size: Number(e.target.value),
																});
															}}
															MenuProps={{
																PaperProps: {
																	style: {
																		backgroundColor: '#ffffff',
																		border: '1px solid rgba(0, 0, 0, 0.12)',
																		borderRadius: '20px',
																		color: '#000000',
																	},
																},
															}}
														>
															{Array.from({ length: 11 }, (_, i) => i - 5).map((size) => (
																<MenuItem key={size} value={size}>
																	{size}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</Grid>
												<Grid item xs={6}>
													<Typography style={{ width: '90%', textAlign: 'left' }}>
														<i className="fa-solid fa-pen-nib"></i>
														Font
													</Typography>
													<FormControl fullWidth>
														<Select
															value={qrprops?.frame_text_font || ''}
															onChange={(e) => {
																setQRProps({
																	...qrprops,
																	frame_text_font: e.target.value,
																});
															}}
															MenuProps={{
																PaperProps: {
																	style: {
																		backgroundColor: '#ffffff',
																		border: '1px solid rgba(0, 0, 0, 0.12)',
																		borderRadius: '20px',
																		color: '#000000',
																	},
																},
															}}
														>
															<MenuItem
																value="times_new_roman"
																style={{ fontFamily: 'Times New Roman' }}
															>
																Times New Roman
															</MenuItem>
															<MenuItem value="georgia" style={{ fontFamily: 'Georgia' }}>
																Georgia
															</MenuItem>
															<MenuItem value="courier" style={{ fontFamily: 'Courier' }}>
																Courier
															</MenuItem>
															<MenuItem value="arial" style={{ fontFamily: 'Arial' }}>
																Arial
															</MenuItem>
															<MenuItem
																value="helvetica"
																style={{ fontFamily: 'Helvetica' }}
															>
																Helvetica
															</MenuItem>
															<MenuItem value="verdana" style={{ fontFamily: 'Verdana' }}>
																Verdana
															</MenuItem>
															<MenuItem value="tahoma" style={{ fontFamily: 'Tahoma' }}>
																Tahoma
															</MenuItem>
															<MenuItem
																value="trebuchet_ms"
																style={{ fontFamily: 'Trebuchet MS' }}
															>
																Trebuchet MS
															</MenuItem>
															<MenuItem
																value="courier_new"
																style={{ fontFamily: 'Courier New' }}
															>
																Courier New
															</MenuItem>
															<MenuItem
																value="lucida_console"
																style={{ fontFamily: 'Lucida Console' }}
															>
																Lucida Console
															</MenuItem>
															<MenuItem value="monaco" style={{ fontFamily: 'Monaco' }}>
																Monaco
															</MenuItem>
															<MenuItem
																value="comic_sans_ms"
																style={{ fontFamily: 'Comic Sans MS' }}
															>
																Comic Sans MS
															</MenuItem>
															<MenuItem value="impact" style={{ fontFamily: 'Impact' }}>
																Impact
															</MenuItem>
														</Select>
													</FormControl>
												</Grid>
											</Grid>
										</Box>
										<Box className={(classes.marginTopBottom, classes.switch)}>
											<Typography style={{ width: '90%', textAlign: 'left' }}>
												<i className="fa-solid fa-droplet"></i> Apply custom colors
											</Typography>

											<Switch
												checked={Boolean(qrprops?.frame_custom_colors)}
												name="pickFrameColor"
												onChange={(e) => {
													if (e.target.checked === false) {
														setQRProps({
															...qrprops,
															frame_color: '',
															frame_text_color: '',
															frame_custom_colors: e.target.checked,
														});
													} else {
														setQRProps({
															...qrprops,
															frame_color: qrprops.foreground_color
																? qrprops.foreground_color
																: qrprops?.frame_color ?? '#000000',
															frame_text_color: qrprops?.background_color
																? qrprops?.background_color
																: qrprops?.frame_text_color ?? '#FFFFFF',
															frame_custom_colors: e.target.checked,
														});
													}
												}}
												inputProps={{ 'aria-label': 'frame color switch' }}
											/>
											{qrprops?.frame_custom_colors === true && (
												<Box sx={{ width: '100%' }}>
													<Box
														style={{
															display: 'flex',
															flexDirection: 'row',
															gap: '16px',
															paddingTop: '16px',
														}}
													>
														{/**
														 * Eye Color Pickers
														 */}
														<QRColorPickerPopover
															setColor={(color) => {
																setQRProps({ ...qrprops, frame_color: color });
															}}
															setError={(e) => {
																console.log(e);
															}}
															id="frameColor"
															name="customQrCodeColors.frameColor"
															label="Frame color"
															color={
																qrprops.foreground_color
																	? qrprops.foreground_color
																	: qrprops?.frame_color ?? '#000000'
															}
														/>

														<QRColorPickerPopover
															setColor={(color) => {
																setQRProps({ ...qrprops, frame_text_color: color });
															}}
															setError={(e) => {
																console.log(e);
															}}
															id="frameTextColor"
															name="customQrCodeColors.frameTextColor"
															label="Frame text color"
															color={
																qrprops?.background_color
																	? qrprops?.background_color
																	: qrprops?.frame_text_color ?? '#FFFFFF'
															}
														/>
													</Box>
												</Box>
											)}
										</Box>
									</Box>
								</TabPanel>

								<TabPanel value={value} index={3} dir={theme.direction}>
									<Box style={{ width: '100%' }}>
										<div className={(classes.marginTopBottom, classes.switch)}>
											<Typography style={{ display: 'inline' }}>
												<i className="fa-regular fa-eye"></i> Show logo
											</Typography>

											<Switch
												style={{ display: 'inline' }}
												checked={qrprops.qr_code_logo_activate === 'true'}
												name="qr_code_logo_activate"
												onChange={(e) => {
													const updates = {
														...qrprops,
														qr_code_logo_activate: e.target.checked ? 'true' : 'false',
														qr_code_logo: e.target.checked
															? qrprops.qr_code_logo ||
															  'https://storage.googleapis.com/liii/locationImages/7148c3e5b85c83e51f65f81432cea0fbxv3DmXMSsBuBjBNbnmhZ.png'
															: '',
													};
													setQRProps(updates);
												}}
												inputProps={{ 'aria-label': 'Show logo' }}
											/>
										</div>
										{qrprops.qr_code_logo_activate === 'true' && (
											<Box>
												<div style={{ cursor: 'pointer' }}>
													<CustomImageUploading
														imageUrl={
															qrprops?.qr_code_logo instanceof File
																? URL.createObjectURL(qrprops.qr_code_logo)
																: qrprops?.qr_code_logo
														}
														setImagePreview={(file: File) => {
															setQRProps({ ...qrprops, qr_code_logo: file });
														}}
														isDrag={isDrag}
													/>
												</div>
												<div className={classes.sliderDisplayInfo}>
													<Typography
														style={{
															width: 'auto',
															textAlign: 'left',
															marginLeft: -10,
														}}
													>
														<i className="fa-solid fa-arrows-up-down-left-right"></i> Logo
														size
													</Typography>
													<p>{qrprops?.qr_code_logo_size ?? 5}</p>
												</div>

												<Slider
													min={5}
													max={35}
													value={Number(qrprops?.qr_code_logo_size)}
													onChange={handleLogoSizeChange}
												/>
											</Box>
										)}
										{/* BACKGROUND IMAGE UPLOAD GO HERE */}
										<>
											<Box style={{ width: '100%' }}></Box>
											<div className={(classes.marginTopBottom, classes.switch)}>
												<Typography style={{ display: 'inline' }}>
													<i className="fa-solid fa-image"></i> Background Image
												</Typography>

												<Switch
													style={{ display: 'inline' }}
													checked={qrprops.qr_code_background_activate === 'true'}
													name="qr_code_background_activate"
													onChange={(e) => {
														if (e.target.checked) {
															setQRProps({
																...qrprops,
																qr_code_background_activate: 'true',
															});
														} else {
															setQRProps({
																...qrprops,
																qr_code_background: '',
																qr_code_background_activate: 'false',
															});
														}
													}}
													inputProps={{ 'aria-label': 'Show background' }}
												/>
											</div>
											{qrprops.qr_code_background_activate === 'true' && (
												<>
													<Box style={{ marginBottom: 10, marginTop: 10, cursor: 'pointer' }}>
														<CustomImageUploading
															imageUrl={
																qrprops?.qr_code_background instanceof File
																	? URL.createObjectURL(qrprops.qr_code_background)
																	: qrprops?.qr_code_background
															}
															setImagePreview={(file: File) => {
																setQRProps({ ...qrprops, qr_code_background: file });
															}}
															isDrag={isDrag}
														/>
													</Box>
													<Box>
														<div className={classes.sliderDisplayInfo}>
															<Typography style={{ width: 'auto', textAlign: 'left' }}>
																Background image transparency
															</Typography>
															<p>
																{Number(qrprops?.qr_code_background_transparency) ?? 0}%
															</p>
														</div>

														<Slider
															value={
																Number(qrprops?.qr_code_background_transparency) ?? 0
															}
															onChange={handleBgImgTransparencyChange}
														/>
													</Box>
												</>
											)}
										</>
									</Box>
								</TabPanel>

								<TabPanel value={value} index={4} dir={theme.direction}>
									<Box
										style={{
											width: '100%',
											display: 'flex',
											flexDirection: 'row',
											gap: '20px',
											justifyContent: 'space-between',
										}}
									>
										<Box style={{ flex: 1 }}>
											<Box className={classes.marginTopBottom}>
												<Typography style={{ width: 'auto', textAlign: 'left' }}>
													<i className="fa-solid fa-maximize"></i> Size
												</Typography>
											</Box>

											<TextField
												size="small"
												fullWidth
												id="size"
												type="number"
												name="size"
												placeholder='Enter overall size here, e.g "500"'
												value={qrprops?.size ? qrprops?.size : ''}
												onChange={(e) => {
													setQRProps({ ...qrprops, size: Number(e.target.value) });
												}}
												variant="outlined"
												InputLabelProps={{
													shrink: true,
													style: {
														marginTop: 5,
													},
												}}
											/>
										</Box>

										<Box style={{ flex: 1 }}>
											<Box className={classes.marginTopBottom}>
												<Typography style={{ width: 'auto', textAlign: 'left' }}>
													<i className="fa-solid fa-expand"></i> Margin Size
												</Typography>
											</Box>
											<TextField
												size="small"
												fullWidth
												id="size"
												type="number"
												name="size"
												placeholder="Enter margin size here"
												value={qrprops?.margin ? qrprops?.margin : ''}
												variant="outlined"
												onChange={(e) => {
													setQRProps({ ...qrprops, margin: Number(e.target.value) });
												}}
												InputLabelProps={{
													shrink: true,
													style: {
														marginTop: 5,
													},
												}}
											/>
										</Box>

										<Box style={{ flex: 1 }}>
											<Box className={classes.marginTopBottom}>
												<Typography style={{ width: '100%', textAlign: 'left' }}>
													<i className="fa-solid fa-check"></i> ECC
												</Typography>
											</Box>

											<Select
												fullWidth
												style={{ height: '40px' }}
												name="errorCorrectionCapability"
												value={qrprops?.ecc ? qrprops?.ecc : 'L'}
												label=" Error correction capability "
												variant="outlined"
												onChange={handleEcc}
												MenuProps={{
													PaperProps: {
														style: {
															backgroundColor: '#ffffff',
															border: '1px solid rgba(0, 0, 0, 0.12)',
															borderRadius: '20px',
															color: '#000000',
														},
													},
												}}
											>
												<MenuItem value="L">Low</MenuItem>
												<MenuItem value="M">Medium</MenuItem>
												<MenuItem value="Q">Quartile</MenuItem>
												<MenuItem value="H">High</MenuItem>
											</Select>
										</Box>
									</Box>
								</TabPanel>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className={classes.buttonBox}>
				<FlatButton className={classes.button} onClick={() => setOpen(false)}>
					Cancel
				</FlatButton>
				<FlatButton className={classes.button} variant="contained" color="primary" onClick={handleSave}>
					Save
				</FlatButton>
			</Box>
		</LiiingoTempDrawer>
	);
};
